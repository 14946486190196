import { Table, TableDrawerContainer, ToggleDrawerButton, } from '@orbica/component-sdk';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AoiRunStatusPill } from '@Components/EntityDetails/AoiRunStatusPill';
import { useGetAoiRunsQuery } from '@Data/aoiRuns/Api';
import { AoiRunStatus } from '@Data/aoiRuns/Types';
import { getDateStringFromTimestamp } from '@Services/helpers/Dates';
import { AoiRunsTableFilter } from '../AoiRunsTableFilter/AoiRunsTableFilter';
import { TabularAoiRunResult } from './TabularAoiRunResult';
const StyledIndicesContainer = styled('div') `
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 948px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1440px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;
function getTableSortingParameter(sorting) {
    if (sorting.length === 0)
        return null;
    const output = {};
    let key = sorting[0].id;
    if (key === 'spectral_index') {
        key = 'index';
    }
    const direction = sorting[0].desc ? 'desc' : 'asc';
    output[key] = direction;
    return output;
}
const renderResults = ({ row }) => {
    const aoiRun = row.original;
    let innerHtml;
    if (aoiRun.status === AoiRunStatus.ERROR) {
        innerHtml = (React.createElement("span", { className: TypographyStyles.largeLight }, "An error occurred during processing. No credits have been deducted from your account for this run."));
    }
    else if ([
        AoiRunStatus.SCHEDULED,
        AoiRunStatus.RESCHEDULED,
        AoiRunStatus.IN_PROGRESS,
    ].includes(aoiRun.status)) {
        innerHtml = (React.createElement("span", { className: TypographyStyles.largeLight },
            Array.isArray(aoiRun === null || aoiRun === void 0 ? void 0 : aoiRun.job_spectral_indices) &&
                (aoiRun === null || aoiRun === void 0 ? void 0 : aoiRun.job_spectral_indices.length) > 1
                ? 'These runs are '
                : 'This run is ',
            "scheduled for",
            ' ',
            getDateStringFromTimestamp(aoiRun.scheduled_date, DateTime.DATE_FULL)));
    }
    else {
        innerHtml = (React.createElement(StyledIndicesContainer, { className: GridStyles.baseGrid }, aoiRun.aoi_run_results.map((result) => (React.createElement(TabularAoiRunResult, { key: result.id, runResult: result, themeName: aoiRun.themeName })))));
    }
    return React.createElement(TableDrawerContainer, null, innerHtml);
};
export const AoiRunsTable = () => {
    const { aoiId } = useParams();
    const [sorting, setSorting] = useState([]);
    const [limit, setLimit] = useState(8);
    const [offset, setOffset] = useState(0);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const { data, isFetching } = useGetAoiRunsQuery(aoiId
        ? {
            aoi_id: aoiId,
            order_by: getTableSortingParameter(sorting),
            limit: limit,
            offset: offset,
            aoi_run_status: selectedStatuses.length > 0
                ? selectedStatuses
                : [
                    AoiRunStatus.COMPLETED,
                    AoiRunStatus.SCHEDULED,
                    AoiRunStatus.RESCHEDULED,
                    AoiRunStatus.IN_PROGRESS,
                    AoiRunStatus.ERROR,
                ],
        }
        : skipToken);
    const { aoiRuns, count } = data !== null && data !== void 0 ? data : { aoiRunResults: [], count: 0 };
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        return [
            columnHelper.accessor('status', {
                header: 'Status',
                cell: (info) => React.createElement(AoiRunStatusPill, { status: info.getValue() }),
            }),
            columnHelper.accessor('scheduled_date', {
                header: 'Run Date',
                cell: (info) => getDateStringFromTimestamp(info.getValue()),
            }),
            columnHelper.accessor('acquisition_time', {
                header: 'Image Date',
                cell: (info) => getDateStringFromTimestamp(info.getValue()),
            }),
            columnHelper.accessor('id', {
                header: null,
                enableSorting: false,
                cell: (info) => {
                    const row = info.row;
                    return (React.createElement(ToggleDrawerButton, { onClick: () => row.toggleExpanded(), open: row.getIsExpanded() }));
                },
                meta: {
                    style: {
                        width: '1%',
                    },
                },
            }),
        ];
    }, []);
    const handleSetStatuses = (statuses) => {
        setOffset(0);
        setSelectedStatuses(statuses);
    };
    return (React.createElement(Table, { columns: columns, data: aoiRuns !== null && aoiRuns !== void 0 ? aoiRuns : [], loading: isFetching, ExpandingProps: {
            renderSubComponent: renderResults,
        }, HeaderProps: {
            filters: (React.createElement(AoiRunsTableFilter, { selectedStatuses: selectedStatuses, onChangeSelectedStatuses: handleSetStatuses })),
        }, SortingProps: {
            sorting: sorting,
            onSortingChange: (s) => setSorting(s),
        }, PaginationProps: !isFetching && {
            limit: limit,
            count: count,
            offset: offset,
            onSetLimit: (l) => setLimit(l),
            onSetOffset: (o) => setOffset(o),
        } }));
};
