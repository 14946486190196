import { ReactComponent as DataLineIcon } from '@Icons/md/data-line.svg';
import { ReactComponent as DataPolygonIcon } from '@Icons/md/data-polygon.svg';
import { ReactComponent as MapIcon } from '@Icons/md/map.svg';
import { ReactComponent as SearchIcon } from '@Icons/md/search.svg';
import { MainContainer, StaticDrawer, Toolbar } from '@orbica/component-sdk';
import React, { useContext, useEffect } from 'react';
import { TimeseriesController } from '@Components/Timeseries/TimeseriesController';
import { AddressSearchSelector } from '@Components/ToolBar/AddressSearchSelector';
import { BaseMapSelector } from '@Components/ToolBar/BaseMapSelector';
import { DrawLineSelector } from '@Components/ToolBar/DrawLineSelector';
import { DrawPolygonSelector } from '@Components/ToolBar/DrawPolygonSelector';
import { clearAllCachedData } from '@Data/h3/Api';
import { resetMapToolbarStates, setMapDrawState } from '@Data/mapToolbar/Api';
import { MapsContext } from '@Services/maps';
import ExploreStyles from '../../ExploreView.scss';
import { ExploreResultsContext, ExploreResultsContextProvider, } from './ResultsContext/ResultsContext';
import { DataFiltersAccordion } from './components/DataFilters/DataFiltersAccordion';
import { IndexLayerSelector } from './components/IndexLayerSelector/IndexLayerSelector';
import { ResultsMap } from './components/ResultsMap/ResultsMap';
import { SymbologyAccordion } from './components/SymbologyAccordion/SymbologyAccordion';
export const ExploreResultsView = () => {
    const mapsContext = useContext(MapsContext);
    const resultsContext = useContext(ExploreResultsContext);
    const handleToolbarClose = () => {
        setMapDrawState();
    };
    useEffect(() => {
        return () => {
            clearAllCachedData();
            mapsContext.onSetBounds(null);
            resultsContext.onSetSelectedAoisFeatureCollection(null);
            mapsContext.onSetZoomLevel(null);
            resetMapToolbarStates();
        };
    }, []);
    return (React.createElement(ExploreResultsContextProvider, null,
        React.createElement(StaticDrawer, { fullWidth: true, className: ExploreStyles.accordionDrawerContainer },
            React.createElement(DataFiltersAccordion, null),
            React.createElement(IndexLayerSelector, null),
            React.createElement(SymbologyAccordion, null)),
        React.createElement(MainContainer, { hasDrawer: true, fullWidth: true, fillScreen: true, GridContainerProps: {
                className: ExploreStyles.mainContainer,
            } },
            React.createElement(ResultsMap, null),
            React.createElement(TimeseriesController, null)),
        React.createElement(Toolbar, { onClose: handleToolbarClose, items: [
                {
                    title: 'Basemaps',
                    IconProps: { icon: React.createElement(MapIcon, null) },
                    content: React.createElement(BaseMapSelector, null),
                    id: 'basemapSelector',
                },
                {
                    title: 'Address Search',
                    IconProps: { icon: React.createElement(SearchIcon, null) },
                    content: React.createElement(AddressSearchSelector, null),
                    id: 'addressSearch',
                },
                {
                    title: 'Measure Distance',
                    IconProps: { icon: React.createElement(DataLineIcon, null) },
                    onActivate: () => setMapDrawState(),
                    onDeactivate: handleToolbarClose,
                    id: 'measureDistance',
                    content: React.createElement(DrawLineSelector, null),
                },
                {
                    title: 'Draw Polygon',
                    IconProps: { icon: React.createElement(DataPolygonIcon, null) },
                    onDeactivate: handleToolbarClose,
                    onActivate: () => setMapDrawState(),
                    content: React.createElement(DrawPolygonSelector, null),
                    id: 'drawPolygon',
                },
            ] })));
};
