export const Navigation = {
    schedule: {
        jobs: {
            path: '/:jobId?',
            getUrl: (jobId) => Navigation.schedule.jobs.path.replace(':jobId?', jobId),
        },
        aois: {
            path: '/:jobId/aois',
            getUrl: (jobId) => Navigation.schedule.aois.path.replace(':jobId', jobId),
        },
        aoiRuns: {
            path: '/:jobId/:aoiId/aoiRuns',
            getUrl: (jobId, aoiId) => Navigation.schedule.aoiRuns.path
                .replace(':jobId', jobId)
                .replace(':aoiId', aoiId),
        },
        aoiRunResult: {
            path: '/:jobId/:aoiId/:aoiRunResultId/runResult',
            getUrl: (jobId, aoiId, aoiRunResultId) => Navigation.schedule.aoiRunResult.path
                .replace(':jobId', jobId)
                .replace(':aoiId', aoiId)
                .replace(':aoiRunResultId', aoiRunResultId),
        },
    },
    monitor: {},
    explore: {
        preExplore: {
            path: '/explore',
        },
        results: {
            path: '/explore/results',
            getUrl: (options) => {
                let path = Navigation.explore.results.path;
                if (options) {
                    Object.entries(options).forEach(([key, value], i) => {
                        const prefix = i === 0 ? '?' : '&';
                        path += `${prefix}${key}=${value}`;
                    });
                }
                return path;
            },
        },
    },
};
