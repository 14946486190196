import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BackButton } from '@Components/Buttons/BackButton';
import { ViewResultsButton } from '@Components/Buttons/ViewResultsButton';
import { TitleWithActionContainer } from '@Components/Containers';
import { ResourceNotFound } from '@Components/ResourceNotFound/ResourceNotFound';
import { useGetAoiQuery } from '@Data/aois/Api';
import { Navigation } from '@Services/navigation/Navigation';
import { AoiDescription } from './components/AoiDescription/AoiDescription';
import { AoiRunsTable } from './components/AoiRunsTable/AoiRunsTable';
export const AoiRunsScheduleView = () => {
    const { jobId, aoiId } = useParams();
    const { data: selectedAoi, isFetching } = useGetAoiQuery(aoiId !== null && aoiId !== void 0 ? aoiId : skipToken);
    if (!isFetching && !selectedAoi) {
        return React.createElement(ResourceNotFound, { resourceName: "Area of Interest" });
    }
    const actions = (React.createElement(React.Fragment, null,
        React.createElement(ViewResultsButton, { href: Navigation.explore.results.getUrl({
                jobId: jobId,
                aoiIds: aoiId,
                load: true,
            }) }),
        React.createElement(BackButton, { href: Navigation.schedule.aois.getUrl(jobId) })));
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleWithActionContainer, { isLoading: isFetching, title: selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.name, actions: actions }),
        React.createElement(AoiDescription, { selectedAoi: selectedAoi, isLoading: isFetching }),
        React.createElement(AoiRunsTable, null)));
};
