import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import H3Reducer from '@Data/h3/Reducer';
import LayersReducer from '@Data/layers/Reducer';
import MapToolbarReducer from '@Data/mapToolbar/Reducer';
import OrganizationsReducer from '@Data/organizations/Reducer';
import RunResultsReducer from '@Data/runResults/Reducer';
import SymbologyReducer from '@Data/symbology/Reducer';
import TimeReducer from '@Data/time/Reducer';
import { ErrorHandler, baseApi, graphqlApi, webApi } from './Api';
export const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
        [webApi.reducerPath]: webApi.reducer,
        [graphqlApi.reducerPath]: graphqlApi.reducer,
        runResults: RunResultsReducer,
        time: TimeReducer,
        layers: LayersReducer,
        h3: H3Reducer,
        symbology: SymbologyReducer,
        mapToolbar: MapToolbarReducer,
        organizations: OrganizationsReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false,
        }).concat([
            baseApi.middleware,
            webApi.middleware,
            graphqlApi.middleware,
            ErrorHandler,
        ]);
    },
});
export const useAppDispatch = useDispatch;
export const useTypedSelector = useSelector;
setupListeners(store.dispatch);
export default store;
