import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BackButton } from '@Components/Buttons/BackButton';
import { ViewResultsButton } from '@Components/Buttons/ViewResultsButton';
import { TitleWithActionContainer } from '@Components/Containers';
import { ResourceNotFound } from '@Components/ResourceNotFound/ResourceNotFound';
import { useGetJobQuery } from '@Data/jobs/Api';
import { Navigation } from '@Services/navigation/Navigation';
import { AoisTable } from './components/AoisTable/AoisTable';
import { JobDescription } from './components/JobDescription/JobDescription';
export const AoisScheduleView = () => {
    const { jobId } = useParams();
    const { data: selectedJob, isFetching } = useGetJobQuery(jobId !== null && jobId !== void 0 ? jobId : skipToken);
    if (!isFetching && !selectedJob) {
        return React.createElement(ResourceNotFound, { resourceName: "Job" });
    }
    const actions = (React.createElement(React.Fragment, null,
        React.createElement(ViewResultsButton, { href: Navigation.explore.results.getUrl({
                jobId: jobId,
                load: true,
            }) }),
        React.createElement(BackButton, { href: "/" })));
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleWithActionContainer, { isLoading: isFetching, title: selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.name, actions: actions }),
        React.createElement(JobDescription, { selectedJob: selectedJob, isLoading: isFetching }),
        React.createElement(AoisTable, null)));
};
