import { CssBaseline } from '@mui/material';
import { LocalizationProvider, ThemeProvider } from '@orbica/component-sdk';
import 'maplibre-gl/dist/maplibre-gl.css';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledToastContainer } from '@Components/ToastContainer/StyledToastContainer';
import { Auth0RedirectProvider } from '@Services/authentication/Auth0RedirectProvider';
import RequireAuth from '@Services/authentication/RequireAuth';
import { MapsProvider } from '@Services/maps';
import { Navigation } from '@Services/navigation/Navigation';
import store from '@Services/redux/reduxStore';
import '@Views/Explore/views/PreExplore/PreExploreView';
import { Signup, Splash, TermsAndConditionsDialog } from '@Views/Login';
import { AoiRunResultView, AoiRunsScheduleView, AoisScheduleView, ExploreView, JobsScheduleView, LoginView, MonitorView, ResultsView, ScheduleView, } from '@Views/index';
export const App = () => {
    return (React.createElement(PostHogProvider, { apiKey: process.env.POSTHOG_API_KEY, options: {
            api_host: 'https://us.posthog.com',
        } },
        React.createElement(Provider, { store: store },
            React.createElement(LocalizationProvider, null,
                React.createElement(ThemeProvider, null,
                    React.createElement(CssBaseline, null),
                    React.createElement(StyledToastContainer, { position: "top-right", hideProgressBar: true, closeButton: false, transition: Slide, icon: false }),
                    React.createElement(MapsProvider, null,
                        React.createElement(BrowserRouter, null,
                            React.createElement(Auth0RedirectProvider, { domain: process.env.AUTH0_DOMAIN, clientId: process.env.AUTH0_CLIENT_ID, authorizationParams: {
                                    redirect_uri: window.location.origin,
                                    audience: process.env.AUTH0_AUDIENCE,
                                }, useRefreshTokens: true, cacheLocation: "localstorage" },
                                React.createElement(Routes, null,
                                    React.createElement(Route, { element: React.createElement(LoginView, null) },
                                        React.createElement(Route, { path: 'login', element: React.createElement(Splash, null) }),
                                        React.createElement(Route, { path: 'signup', element: React.createElement(Signup, null) })),
                                    React.createElement(Route, { element: React.createElement(TermsAndConditionsDialog, null), path: 'termsandconditions' }),
                                    React.createElement(Route, { element: React.createElement(RequireAuth, { component: ScheduleView }) },
                                        React.createElement(Route, { path: Navigation.schedule.jobs
                                                .path, element: React.createElement(JobsScheduleView, null) }),
                                        React.createElement(Route, { path: Navigation.schedule.aois
                                                .path, element: React.createElement(AoisScheduleView, null) }),
                                        React.createElement(Route, { path: Navigation.schedule.aoiRuns
                                                .path, element: React.createElement(AoiRunsScheduleView, null) }),
                                        React.createElement(Route, { path: Navigation.schedule
                                                .aoiRunResult.path, element: React.createElement(AoiRunResultView, null) })),
                                    React.createElement(Route, { path: "/monitor", element: React.createElement(RequireAuth, { component: MonitorView }) }),
                                    React.createElement(Route, { path: Navigation.explore.preExplore
                                            .path, element: React.createElement(RequireAuth, { component: ExploreView }) }),
                                    React.createElement(Route, { path: Navigation.explore.results.path, element: React.createElement(RequireAuth, { component: ResultsView }) }))))))))));
};
