import { PrimaryButton } from '@orbica/component-sdk';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isTrialOrganizationState } from '@Data/organizations/Reducer';
import { useGetPlanQuery } from '@Data/organizations/Api';
import { skipToken } from '@reduxjs/toolkit/query';
import { organzationsIdState, orgPurchaseURLState, organizationsAuth0IdState } from '@Data/organizations/Reducer';
const StyledButton = styled(PrimaryButton) `
    min-width: 140px;
`;
export const UpgradeSubscriptionButton = () => {
    const isTrialOrg = useSelector(isTrialOrganizationState);
    const orgId = useSelector(organzationsIdState);
    const purchaseURL = useSelector(orgPurchaseURLState);
    const auth0OrgId = useSelector(organizationsAuth0IdState);
    const { data: planName } = useGetPlanQuery(Boolean(auth0OrgId) && !isTrialOrg ? auth0OrgId : skipToken);
    return (isTrialOrg && (React.createElement(StyledButton, { href: `https://www.orbica.com/pricing?${purchaseURL}&plan=${planName}`, target: "_blank", size: "small" }, "Upgrade Plan")));
};
