import { ReactComponent as FileIcon } from '@Icons/md/file-plus.svg';
import { ReactComponent as PenIcon } from '@Icons/md/pen-tool.svg';
import { ReactComponent as CancelIcon } from '@Icons/md/x.svg';
import { AssistiveText, Dialog, PrimaryButton, SecondaryButton, } from '@orbica/component-sdk';
import area from '@turf/area';
import centerOfMass from '@turf/center-of-mass';
import _cloneDeep from 'lodash/cloneDeep';
import _isNil from 'lodash/isNil';
import { usePostHog } from 'posthog-js/react';
import React, { useId, useRef, useState } from 'react';
import styled from 'styled-components';
import { AddAreasOfInterestMap } from '@Components/AreaOfInterest/AddAreasOfInterestMap/AddAreasOfInterestMap';
import { CloudCoverAreaOfInterestListItem } from '@Components/AreaOfInterest/CloudCoverAreaOfInterestListItem';
import { Analytics } from '@Services/analytics/Events';
import { convertAreaFromSqm } from '@Services/helpers/Area';
import { TitleAndDescription } from './TitleAndDescription';
function getQualitativeSymbology(index) {
    const brews = [
        '#8dd3c7',
        '#ffffb3',
        '#bebada',
        '#fb8072',
        '#80b1d3',
        '#fdb462',
        '#b3de69',
        '#fccde5',
        '#d9d9d9',
        '#bc80bd',
        '#ccebc5',
        '#ffed6f',
    ];
    const moddedIndex = index % brews.length;
    return brews[moddedIndex];
}
const CONTAINER_HEIGHT = '50vh';
const AoiGrid = styled('div') `
    grid-column: 1/-1;
    display: grid;
    gap: inherit;

    grid-template-columns: repeat(12, 1fr);
`;
const GridContentContainer = styled('div') `
    gap: 12px;
    padding: 1px;
    max-height: ${CONTAINER_HEIGHT};
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .scroll-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
    }
`;
const MapContainer = styled(GridContentContainer) `
    grid-column: 1/-1;
    height: 50vh;

    @media (min-width: 1280px) {
        grid-column: span 8;
    }

    @media (min-width: 1600px) {
        grid-column: span 9;
    }
`;
const AoiListContainer = styled(GridContentContainer) `
    grid-column: 1/-1;

    @media (min-width: 1280px) {
        grid-column: span 4;
    }

    @media (min-width: 1600px) {
        grid-column: span 3;
    }
`;
const VisuallyHiddenInput = styled('input')({
    display: 'none',
});
export const AreasOfInterestStage = (props) => {
    const inputRef = useRef(null);
    const aoiMapRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [deleteAoi, setDeleteAoi] = useState(null);
    const posthog = usePostHog();
    const handleClick = () => {
        var _a;
        if ((_a = aoiMapRef.current) === null || _a === void 0 ? void 0 : _a.getHasLoaded()) {
            const drawState = isDrawing ? 'simple_select' : 'draw_polygon';
            aoiMapRef.current.setDrawState(drawState);
            setIsDrawing(!isDrawing);
        }
    };
    const handleAddFeature = (newFeature) => {
        const clonedFeatures = _cloneDeep(props.areasOfInterest);
        clonedFeatures.push(newFeature);
        props.onUpdateAreasOfInterest(clonedFeatures);
        setIsDrawing(false);
        const location = centerOfMass(newFeature);
        posthog === null || posthog === void 0 ? void 0 : posthog.capture(Analytics.CreateJob.AoiAdded, {
            size: convertAreaFromSqm(newFeature.properties.area),
            location: location.geometry.coordinates,
        });
    };
    const handleUpdateFeature = (updatedFeature) => {
        const clonedFeatures = _cloneDeep(props.areasOfInterest);
        const foundFeatureIndex = clonedFeatures.findIndex((f) => f.id === updatedFeature.id);
        clonedFeatures[foundFeatureIndex] = updatedFeature;
        props.onUpdateAreasOfInterest(clonedFeatures);
        setIsDrawing(false);
    };
    const handleDeleteFeature = () => {
        aoiMapRef.current.handleDelete(deleteAoi.id);
        const clonedFeatures = _cloneDeep(props.areasOfInterest);
        const foundFeatureIndex = clonedFeatures.findIndex((f) => f.id === deleteAoi.id);
        clonedFeatures.splice(foundFeatureIndex, 1);
        props.onUpdateAreasOfInterest(clonedFeatures);
        setDeleteAoi(null);
    };
    const symbolizedFeatures = props.areasOfInterest.map((aoi, i) => {
        return Object.assign(Object.assign({}, aoi), { properties: Object.assign(Object.assign({}, aoi.properties), { color: getQualitativeSymbology(i) }) });
    });
    const handleAddFeaturesFromFile = (event) => {
        const fileReader = new FileReader();
        const file = event.target.files[0];
        fileReader.readAsText(file, 'UTF-8');
        fileReader.onload = (e) => {
            const geojson = JSON.parse(e.target.result);
            const clonedFeatures = _cloneDeep(props.areasOfInterest);
            if (geojson.features && Array.isArray(geojson.features)) {
                const polygons = geojson.features.filter((feature) => {
                    var _a;
                    return (feature.geometry && ((_a = feature.geometry) === null || _a === void 0 ? void 0 : _a.type) === 'Polygon');
                });
                polygons.forEach((polygon) => {
                    var _a;
                    polygon.properties = Object.assign(Object.assign({}, polygon.properties), { area: area(polygon) });
                    clonedFeatures.push(Object.assign(Object.assign({}, polygon), { id: (_a = polygon.id) !== null && _a !== void 0 ? _a : crypto.randomUUID() }));
                });
                props.onUpdateAreasOfInterest(clonedFeatures);
                posthog === null || posthog === void 0 ? void 0 : posthog.capture(Analytics.CreateJob.GeoJsonImported, {
                    fileName: file.name,
                    features: polygons.map((p) => ({
                        location: centerOfMass(p).geometry.coordinates,
                        area: p.properties.area,
                    })),
                    count: polygons.length,
                });
            }
        };
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleAndDescription, { title: "Add the areas you want to monitor.", description: "Add areas by plotting polygons on the map, or by uploading GeoJSON files." }),
        React.createElement(AoiGrid, null,
            React.createElement(MapContainer, null,
                React.createElement(AddAreasOfInterestMap, { features: symbolizedFeatures, onAddFeature: handleAddFeature, onUpdateFeature: handleUpdateFeature, ref: aoiMapRef })),
            React.createElement(AoiListContainer, null,
                React.createElement(VisuallyHiddenInput, { ref: inputRef, type: "file", accept: ".json,.geojson", onChange: handleAddFeaturesFromFile }),
                React.createElement(PrimaryButton, { onClick: handleClick, StartIconProps: {
                        icon: isDrawing ? React.createElement(CancelIcon, null) : React.createElement(PenIcon, null),
                    } }, isDrawing
                    ? 'Cancel Drawing'
                    : 'Plot a new area of interest'),
                React.createElement("div", null,
                    React.createElement(SecondaryButton, { onClick: () => { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click(); }, style: { width: '100%' }, StartIconProps: {
                            icon: React.createElement(FileIcon, null),
                        } }, "Upload a file"),
                    React.createElement(AssistiveText, { message: "Only GeoJSON files are supported" })),
                React.createElement("div", { className: "scroll-container" }, symbolizedFeatures.map((aoi) => {
                    var _a;
                    return (React.createElement(CloudCoverAreaOfInterestListItem, { feature: aoi, key: (_a = aoi.id) !== null && _a !== void 0 ? _a : useId(), color: aoi.properties.color, onZoomToBounds: () => aoiMapRef.current.zoomToBounds([aoi]), onUpdate: props.onUpdateFeature, onDelete: () => setDeleteAoi(aoi), baselineStartDate: props.baselineStartDate, baselineEndDate: props.baselineEndDate, onUpdateFeature: props.onUpdateFeature }));
                })))),
        React.createElement(Dialog, { title: "Delete Area of Interest", open: !_isNil(deleteAoi), onClose: () => setDeleteAoi(null), FooterProps: {
                submitText: `Delete ${(deleteAoi === null || deleteAoi === void 0 ? void 0 : deleteAoi.properties.name) !== ''
                    ? deleteAoi === null || deleteAoi === void 0 ? void 0 : deleteAoi.properties.name
                    : ''}`,
                onSubmit: handleDeleteFeature,
            } })));
};
