var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AssistiveText, DateRangePicker, DrawerAccordion, FormControl, Label, PrimaryButton, } from '@orbica/component-sdk';
import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Legend } from '@Components/Symbology/Legend/Legend';
import { OpacitySlider } from '@Components/Symbology/OpacitySlider/OpacitySlider';
import { getAugmentedThemes, getSentinelCollectionImages, } from '@Data/imagery/Api';
import { SENTINEL_2_MIN_DATE } from '@Data/imagery/helpers';
import { layersSelectedIndexState } from '@Data/layers/Reducer';
import { resetSymbology } from '@Data/symbology/Api';
import { MapsContext } from '@Services/maps';
import { createToast } from '@Services/notifications/NotificationService';
import Styles from './PreExploreDrawer.scss';
import { SatelliteImageSelectorDialog } from './SatelliteImageSelectorDialog';
import { ThemeSelector } from './ThemeSelector';
export const PreExploreDrawer = (props) => {
    const mapsContext = useContext(MapsContext);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sentinelCollectionResponse, setSentinelCollectionResponse] = useState(null);
    const [isFetchingCollections, setIsFetchingCollections] = useState(false);
    const [themes, setThemes] = useState(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const indexInfo = useSelector(layersSelectedIndexState);
    const areFieldsFilled = !_isNil(startDate) && !_isNil(endDate);
    const canQuery = areFieldsFilled && mapsContext.zoomLevel > 9;
    useEffect(() => {
        return () => {
            resetSymbology();
        };
    }, []);
    useEffect(() => {
        const handleGetAugmentedThemes = () => __awaiter(void 0, void 0, void 0, function* () {
            const augmentedThemes = yield getAugmentedThemes();
            setThemes(augmentedThemes);
        });
        if (!hasLoaded) {
            handleGetAugmentedThemes();
        }
    }, [hasLoaded]);
    const handleFetchImages = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsFetchingCollections(true);
        const response = yield getSentinelCollectionImages(startDate, endDate, mapsContext.bounds);
        setIsFetchingCollections(false);
        if (response.numberMatched > 0) {
            setSentinelCollectionResponse(response);
        }
        else {
            createToast('No results were found for that date, in that area', 'error');
        }
    });
    const handleSelectImage = (collections) => {
        props.onSetCollectionIdDates(collections);
        setSentinelCollectionResponse(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerAccordion, { title: "Date range", defaultExpanded: true },
            React.createElement(DateRangePicker, { startDate: startDate, endDate: endDate, onChangeStartDate: (d) => setStartDate(d), onChangeEndDate: (d) => setEndDate(d), minDate: SENTINEL_2_MIN_DATE, maxDate: DateTime.now(), StartDateProps: {
                    containerClassName: Styles.drawerChild,
                    label: 'Start Date',
                }, EndDateProps: {
                    containerClassName: Styles.drawerChild,
                    label: 'End Date',
                } }),
            React.createElement("div", { className: Styles.submitButtonContainer },
                React.createElement(PrimaryButton, { disabled: !canQuery, onClick: handleFetchImages, isLoading: isFetchingCollections, loadingText: "Fetching Results" }, "Fetch Results"),
                !canQuery && areFieldsFilled && (React.createElement(AssistiveText, { error: true, message: "Zoom to a smaller area on the map" })),
                !_isNil(props.collections) &&
                    props.collections.length > 0 && (React.createElement(FormControl, { containerClassName: Styles.imageDateContainer },
                    React.createElement(Label, { label: "Selected image date" }),
                    React.createElement("span", null, DateTime.fromISO(props.collections[0].date).toLocaleString(DateTime.DATE_MED)))))),
        !_isNil(props.collections) && props.collections.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(DrawerAccordion, { title: "Spectral Indices", defaultExpanded: true },
                React.createElement(ThemeSelector, { onSetLayer: props.onSetLayer, selectedLayer: props.selectedLayer, themes: themes })),
            React.createElement(DrawerAccordion, { title: "Symbology" },
                (!_isNil(indexInfo) ||
                    !_isNil(props.selectedLayer)) && (React.createElement(Legend, { type: "explore", selectedLayer: props.selectedLayer })),
                React.createElement(OpacitySlider, null)))),
        React.createElement(SatelliteImageSelectorDialog, { onClose: () => setSentinelCollectionResponse(null), onSelectImage: handleSelectImage, collectionResponse: sentinelCollectionResponse })));
};
