import { ReactComponent as AccountCompanyIcon } from '@Icons/md/account-company.svg';
import { Badge, PrimaryButton } from '@orbica/component-sdk';
import ColorStyles from '@orbica/component-sdk/build/css/colors.scss';
import TypographyStyles from '@orbica/component-sdk/build/css/typography.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useGetPlanQuery } from '@Data/organizations/Api';
import { isTrialOrganizationState, organizationsAuth0IdState, organizationsDisplayNameState, organzationsIdState, orgPurchaseURLState } from '@Data/organizations/Reducer';
import { getOrganizationCreditStatus } from '@Data/organizations/helpers';
const StyledCreditContainer = styled('div')(() => `
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;
    background-color: ${ColorStyles.greyscale100};
    gap: 4px;

    .title {
        display: flex;
        align-items: center;
        span {
            margin-left: 10px;
        }
        margin-bottom: 4px;
    }    

    .info {
        margin-left: 6px;
    }

    .patter {
        gap: 6px;
    }

    .badgeContent {
        height: 4px;
        margin-left: 16px;
    }
`);
export const OrganizationCreditInformation = (props) => {
    const auth0OrgId = useSelector(organizationsAuth0IdState);
    const isTrialOrg = useSelector(isTrialOrganizationState);
    const orgDisplayName = useSelector(organizationsDisplayNameState);
    const orgId = useSelector(organzationsIdState);
    const purchaseURL = useSelector(orgPurchaseURLState);
    const { data: planName } = useGetPlanQuery(Boolean(auth0OrgId) && !isTrialOrg ? auth0OrgId : skipToken);
    const title = isTrialOrg ? 'Trial Plan' : `${planName} Plan`;
    const creditStatus = getOrganizationCreditStatus(props.org_required_stu, props.org_available_stu);
    console.log(orgId);
    const patter = props.org_required_stu < props.org_available_stu ? (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            React.createElement("b", null, props.org_required_stu.toLocaleString()),
            " credits will be consumed on the next set of runs."),
        React.createElement("p", null,
            "Only active jobs that are created by",
            ' ',
            isTrialOrg ? 'you' : 'your organisation',
            " will consume your",
            ' ',
            isTrialOrg ? '' : "organisation's",
            " credits."))) : (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            React.createElement("b", null, props.org_required_stu.toLocaleString()),
            " credits are required for ",
            isTrialOrg ? 'your' : "your organisation's",
            ' ',
            "next set of runs."),
        React.createElement("p", null,
            "Either",
            ' ',
            isTrialOrg
                ? 'upgrade your plan'
                : 'purchase additional credits',
            ", or deactivate some of your active jobs.")));
    return (!_isNil(props.org_available_stu) &&
        !_isNil(props.org_required_stu) && (React.createElement(StyledCreditContainer, null,
        React.createElement("div", { className: "title" },
            React.createElement(AccountCompanyIcon, null),
            React.createElement("span", { className: TypographyStyles.baseLight },
                orgDisplayName,
                " (",
                title,
                ")")),
        React.createElement("div", null,
            React.createElement("span", { className: TypographyStyles.baseBold }, props.org_available_stu.toLocaleString()),
            React.createElement("span", { className: cx(TypographyStyles.smallRegular, 'info') }, "remaining credits"),
            React.createElement(Badge, { badgeContent: "!", invisible: _isNil(creditStatus) || creditStatus === 'success', state: creditStatus, position: "top" },
                React.createElement("div", { className: "badgeContent" }))),
        React.createElement("div", { className: cx(TypographyStyles.xsmallRegular, 'patter') }, patter),
        React.createElement(PrimaryButton, { size: "small", href: `https://www.orbica.com/pricing?${purchaseURL}&plan=${planName}`, target: "_blank" }, isTrialOrg ? 'Upgrade Plan' : 'Purchase Credits'))));
};
