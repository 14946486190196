var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axiosInstance from '@Services/axios/AxiosClient';
import { TagType, WEB_API_URL, graphqlApi, store, webApi, } from '@Services/redux';
import { OrganizationsActions } from './Reducer';
const organizationsWebApi = webApi.injectEndpoints({
    endpoints: (build) => ({
        onboardOrganization: build.mutation({
            query: (params) => {
                return {
                    method: 'POST',
                    url: 'organizations/onboard',
                    data: params,
                };
            },
        }),
        getPlans: build.query({
            query: () => {
                return {
                    method: 'GET',
                    url: 'organizations/get_plans',
                };
            },
        }),
    }),
});
const organizationsGqlApi = graphqlApi.injectEndpoints({
    endpoints: (build) => ({
        getCreditInformation: build.query({
            query: (auth0OrgId) => ({
                body: `
                    query GetSubscriptionInfo {
                        subscription(where: {organization: {auth0_id: {_eq: ${auth0OrgId}}}}) {
                            org_required_stu
                            org_available_stu
                        }
                    }

                `,
            }),
            providesTags: [TagType.STUS],
            transformResponse: (gqlResponse) => gqlResponse.subscription[0],
        }),
        getPlan: build.query({
            query: (auth0OrgId) => ({
                body: `
                    query GetSubscriptionInfo {
                        subscription(where: {organization: {auth0_id: {_eq: ${auth0OrgId}}}}) {
                            plan {
                                name
                            }
                        }
                    }

                `,
            }),
            transformResponse: (gqlResponse) => { var _a; return (_a = gqlResponse.subscription[0].plan) === null || _a === void 0 ? void 0 : _a.name; },
        }),
    }),
});
export const { useOnboardOrganizationMutation, useGetPlansQuery } = organizationsWebApi;
export const { useGetCreditInformationQuery, useGetPlanQuery } = organizationsGqlApi;
export function initializeOrgData(auth0Id, displayName, graphqlId, subscriptionType) {
    store.dispatch(OrganizationsActions.initialize({
        auth0Id: auth0Id,
        displayName: displayName,
        graphqlId: graphqlId,
        type: subscriptionType,
        purchaseURL: `org_id=${graphqlId}&account_type=${subscriptionType}`,
    }));
}
export function onboardOrganization(data) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            yield axiosInstance.post(`${WEB_API_URL}/organizations/onboard`, data);
            return { message: 'success', status: 200 };
        }
        catch (error) {
            return {
                message: error.response.data.message,
                status: error.response.status,
            };
        }
    });
}
