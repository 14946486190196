var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _groupBy from 'lodash/groupBy';
import _isNil from 'lodash/isNil';
import { intializeLayerIndices } from '@Data/layers/Api';
import { LayersActions } from '@Data/layers/Reducer';
import { initializeSymbology } from '@Data/symbology/Api';
import { SymbologyActions } from '@Data/symbology/Reducer';
import { initializePlaybackData } from '@Data/time/Api';
import { TimeActions } from '@Data/time/Reducer';
import axiosInstance from '@Services/axios/AxiosClient';
import { blobToUrl } from '@Services/helpers/Files';
import { TagType, WEB_API_URL, baseApi, store, webApi } from '@Services/redux';
import { RunResultsActions } from './Reducer';
import { mapComparisonResult, transformFilterRunResultMetadataResponse, transformGetRunResultResponse, } from './helpers';
export const runResultsBaseApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getRunResult: build.query({
            query: (aoiRunResultId) => ({
                url: `get_aoi_run_result/${aoiRunResultId}`,
                method: 'GET',
            }),
            transformResponse: (gqlResponse) => transformGetRunResultResponse(gqlResponse),
            providesTags: [TagType.COMPARISON_RESULT],
        }),
        getComparisonResult: build.query({
            query: (aoiRunResultId) => ({
                url: `get_comparison_result/${aoiRunResultId}`,
                method: 'GET',
            }),
            transformResponse: (gqlResponse) => mapComparisonResult(gqlResponse.comparison_result[0]),
            providesTags: [TagType.COMPARISON_RESULT],
        }),
    }),
});
export const runResultsWebApi = webApi.injectEndpoints({
    endpoints: (build) => ({
        filterRunResultMetadata: build.query({
            query: (payload) => ({
                url: 'data_filters/get_run_result_metadata',
                method: 'POST',
                data: payload,
            }),
            transformResponse: (response) => transformFilterRunResultMetadataResponse(response),
        }),
    }),
});
export const { useGetComparisonResultQuery, useGetRunResultQuery } = runResultsBaseApi;
export const { useFilterRunResultMetadataQuery } = runResultsWebApi;
export function downloadFileFromS3(url) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield axiosInstance({
            url: `${WEB_API_URL}/data_access/s3/${url}`,
            responseType: 'blob',
        });
        return blobToUrl(response);
    });
}
export function resetMetadata() {
    store.dispatch(RunResultsActions.reset());
    store.dispatch(TimeActions.reset());
    store.dispatch(LayersActions.reset());
    store.dispatch(SymbologyActions.reset());
}
export function initializeFromRawMetadata(metadataResponse) {
    store.dispatch(RunResultsActions.setRunResultsMetadata(metadataResponse.run_result_metadata));
    initializePlaybackData(metadataResponse.run_result_metadata);
    intializeLayerIndices(metadataResponse.run_result_metadata, metadataResponse.job_theme_details);
    initializeRasterAndTrueColorLayers(metadataResponse.run_result_metadata);
    initializeSymbology(metadataResponse);
}
function initializeRasterAndTrueColorLayers(metadata) {
    const metadataByDate = _groupBy(metadata, 'epochTime');
    const rasterLayers = {};
    const trueColorLayers = {};
    const displayTrueColor = !metadata.some((m) => _isNil(m.rgb_cog_metadata));
    Object.entries(metadataByDate).forEach(([epochTime, temporalMetadata]) => {
        const metadataByIndex = _groupBy(temporalMetadata, 'spectral_index');
        Object.entries(metadataByIndex).forEach(([index, indexedMetadata]) => {
            const epochIndexId = `${epochTime}${index}`;
            rasterLayers[epochIndexId] = {
                id: epochIndexId,
                spectral_index: index,
                scheduled_date: indexedMetadata[0].scheduled_date,
                epochTime: parseInt(epochTime),
                index_cog_urls: indexedMetadata.map((m) => m.index_cog_url),
            };
            if (displayTrueColor) {
                trueColorLayers[epochTime] = {
                    id: epochTime,
                    rgb_cog_urls: indexedMetadata.map((m) => {
                        const trueColorLayer = {
                            url: m.rgb_cog_url,
                        };
                        if (!_isNil(m.rgb_cog_metadata)) {
                            trueColorLayer['b1'] = parseRgbCogMetadata(m.rgb_cog_metadata.red);
                            trueColorLayer['b2'] = parseRgbCogMetadata(m.rgb_cog_metadata.green);
                            trueColorLayer['b3'] = parseRgbCogMetadata(m.rgb_cog_metadata.blue);
                        }
                        return trueColorLayer;
                    }),
                };
            }
        });
    });
    store.dispatch(RunResultsActions.setRunResultRasterLayers(rasterLayers));
    store.dispatch(RunResultsActions.setRunResultTrueColorLayers(displayTrueColor ? trueColorLayers : null));
}
function parseRgbCogMetadata(rgbCogMetadatum) {
    const max = rgbCogMetadatum.mean + 3 * rgbCogMetadatum.std;
    return [rgbCogMetadatum.min, max];
}
