import { Select, SelectOption } from '@orbica/component-sdk';
import React from 'react';
import { AoiRunStatusPill } from '@Components/EntityDetails/AoiRunStatusPill';
import { AoiRunStatus } from '@Data/aoiRuns/Types';
import Styles from './Filters.scss';
function renderSelectedAoiRunResults(values) {
    return (React.createElement("div", { className: Styles.resultsContainer }, values.map((v) => (React.createElement(AoiRunStatusPill, { status: v, size: "small", key: v, as: "span" })))));
}
export const AoiRunStatusFilter = (props) => {
    return (React.createElement(Select, { value: props.selectedStatuses, multiple: true, onChange: (e, v) => props.onChange(v), placeholder: "Filter by status", disableOptionStyling: true, renderValue: renderSelectedAoiRunResults, size: "small", containerClassName: Styles.filterSelectContainer },
        React.createElement(SelectOption, { value: AoiRunStatus.COMPLETED },
            React.createElement(AoiRunStatusPill, { size: "small", status: AoiRunStatus.COMPLETED, as: "span" })),
        React.createElement(SelectOption, { value: AoiRunStatus.SCHEDULED },
            React.createElement(AoiRunStatusPill, { size: "small", status: AoiRunStatus.SCHEDULED, as: "span" })),
        React.createElement(SelectOption, { value: AoiRunStatus.RESCHEDULED },
            React.createElement(AoiRunStatusPill, { size: "small", status: AoiRunStatus.RESCHEDULED, as: "span" })),
        React.createElement(SelectOption, { value: AoiRunStatus.IN_PROGRESS },
            React.createElement(AoiRunStatusPill, { size: "small", status: AoiRunStatus.IN_PROGRESS, as: "span" })),
        React.createElement(SelectOption, { value: AoiRunStatus.ERROR },
            React.createElement(AoiRunStatusPill, { size: "small", status: AoiRunStatus.ERROR, as: "span" }))));
};
