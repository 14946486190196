export var AoiRunResultValue;
(function (AoiRunResultValue) {
    AoiRunResultValue["NO_CHANGE"] = "NO_CHANGE";
    AoiRunResultValue["EXCEEDED"] = "EXCEEDED";
    AoiRunResultValue["NO_RESULT"] = "NO_RESULT";
})(AoiRunResultValue || (AoiRunResultValue = {}));
export var AoiRunStatus;
(function (AoiRunStatus) {
    AoiRunStatus["RESCHEDULED"] = "RESCHEDULED";
    AoiRunStatus["COMPLETED"] = "COMPLETED";
    AoiRunStatus["SCHEDULED"] = "SCHEDULED";
    AoiRunStatus["IN_PROGRESS"] = "IN_PROGRESS";
    AoiRunStatus["ERROR"] = "ERROR";
})(AoiRunStatus || (AoiRunStatus = {}));
export var AoiRunType;
(function (AoiRunType) {
    AoiRunType["BASELINE_IMAGE"] = "BASELINE_IMAGE";
    AoiRunType["MONITORING_IMAGE"] = "MONITORING_IMAGE";
    AoiRunType["BASELINE_AGGREGATE_IMAGE"] = "BASELINE_AGGREGATE_IMAGE";
})(AoiRunType || (AoiRunType = {}));
