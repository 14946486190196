var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAuth0 } from '@auth0/auth0-react';
import '@auth0/auth0-react';
import { Select, SelectOption, TertiaryButton, TextInput, } from '@orbica/component-sdk';
import { isEmailAddressValid } from '@orbica/component-sdk';
import { PrimaryButton } from '@orbica/component-sdk';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onboardOrganization, useGetPlansQuery } from '@Data/organizations/Api';
import { createToast } from '@Services/notifications/NotificationService';
import Styles from '../LoginView.scss';
const PasswordRegex = new RegExp(/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/);
const Info = () => {
    return (React.createElement("div", { className: Styles.passwordInfo },
        React.createElement("span", null, "At least 8 characters long, and contains at least one"),
        React.createElement("li", null, "Special character (eg. !@#$%^&*)"),
        React.createElement("li", null, "Lower case (a-z)"),
        React.createElement("li", null, "Upper case (A-Z)"),
        React.createElement("li", null, "Number (0-9)")));
};
export const Signup = () => {
    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    const [plan, setPlan] = useState(null);
    const [emailAddress, setEmailAddress] = useState('');
    const [orgName, setOrgName] = useState('');
    const [password, setPassword] = useState('');
    const { data: plans, isFetching: arePlansFetching } = useGetPlansQuery();
    const [isLoading, setIsLoading] = useState(false);
    const handleChangeOrgType = (e, v) => {
        setPlan(v);
    };
    const isPasswordInvalid = PasswordRegex.test(password);
    const isEmailInvalid = !isEmailAddressValid(emailAddress);
    const canCreateAccount = !_isNil(plan) &&
        emailAddress.length > 0 &&
        password.length > 0 &&
        orgName.length > 0 &&
        !isPasswordInvalid &&
        !isEmailInvalid;
    const handleCreateOrganization = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const { message, status } = yield onboardOrganization({
            email_address: emailAddress,
            password: password,
            organization_name: orgName,
            plan_id: plan.id,
        });
        setIsLoading(false);
        if (status === 200) {
            createToast('Account created!', 'success', 'Check your emails to verify your new account.');
            navigate('/login');
        }
        else {
            createToast(message, 'error');
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: Styles.createAccountContainer },
            React.createElement("div", { className: Styles.h2Container },
                React.createElement("span", { className: Styles.h2 }, "Create a free trial account")),
            React.createElement("div", { className: Styles.signUpContainer },
                React.createElement(TextInput, { value: emailAddress, onChange: (e) => setEmailAddress(e.target.value), label: "Email Address", placeholder: "Email Address", error: emailAddress.length > 0 && isEmailInvalid }),
                React.createElement(TextInput, { type: "password", value: password, onChange: (e) => setPassword(e.target.value), label: "Password", placeholder: "Password", info: React.createElement(Info, null), error: password.length > 0 && isPasswordInvalid, errorMessage: "Your password does not meet our complexity requirements" }),
                React.createElement(TextInput, { value: orgName, onChange: (e) => setOrgName(e.target.value), label: "Organisation Name", placeholder: "Organisation Name", error: orgName.length > 0 && orgName.length < 4, errorMessage: "Your Organisation's Name must be longer than 3 characters" }),
                React.createElement(Select, { value: plan, renderValue: (plan) => plan.name, onChange: handleChangeOrgType, label: "Organisation Type", placeholder: "Organisation Type", loading: arePlansFetching }, plans === null || plans === void 0 ? void 0 : plans.map((plan) => (React.createElement(SelectOption, { value: plan, key: plan.id }, plan.name))))),
            React.createElement("div", { className: Styles.signUpContainer },
                React.createElement(PrimaryButton, { loadingText: 'Creating Account', disabled: !canCreateAccount, displayChevron: true, onClick: handleCreateOrganization, className: Styles.btnCreateAccount, isLoading: isLoading }, "Create Account"),
                React.createElement("div", { className: Styles.existingAccountContainer },
                    React.createElement("span", null, "Already have an account?"),
                    React.createElement(TertiaryButton, { textColor: Styles.brandGreen, "$removePadding": true, className: Styles.btnExistingLogin, onClick: loginWithRedirect, size: "small" }, "Login")),
                React.createElement("div", { className: Styles.tsandcsContainer },
                    React.createElement("p", null,
                        "By proceeding, you agree to the",
                        React.createElement(TertiaryButton, { textColor: Styles.brandGreen, "$removePadding": true, className: Styles.btnExistingLogin, size: "small", href: "/termsandconditions", target: "_blank" }, "Terms and Conditions")))))));
};
